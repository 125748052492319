import React, { useEffect, useRef, useState } from "react"
import {
  ContainerRow,
  ImagePart,
  Info,
  List,
  Price,
  ServicesContainer,
  TextPart,
  Wrapper,
  SaleWrapper
} from "../../components/businessRelocationStyled"
import landingImage from "../../assets/Artboard.png"
import { SubmitButton, ToggleH2Styled } from "../../components/careercomponents/careerDroping/dropStyle"
import {
  H2Styled, NavLink, SeemoreWrapper,
  ServiceTitle,
} from "../../components/homecomponents/homeServices/homeServiceStyle"
import useTranslations from "../../components/useTranslations"
import ServiceDropWrap from "../../components/servicecomponents/serviceDrop/servicedrop"
import CalcImg from "../../assets/homeImages/icons/calculator.svg"
import { FormColumn } from "../../components/contactcomponts/contactMainStyle"
import FormindIviduals from "../../components/contactcomponts/contactForm/formindividuals"
import triple from "../../api/triple"
import apiUrl from "../../api/api.json"
import UsefulNews from "../../components/news/secondnewspage"
import SharedLogo from "../../assets/sharedLogo/share_logo(3c).png"
import SEO from "../../components/seo"
import imagge from "../../assets/for_web_riple.png"

const Index = ({ pageContext, langText, lang }) => {
  const { home, contact , moveBusinessToArmenia, declaration} = useTranslations()
  const [serviceData, setserviceData] = useState([
    {
      data: {
        id: 0,
        scroll_id: `test_1`,
        image: CalcImg,
        paragraph: `${declaration.declTitle1}`,
        text: `● ${declaration.declText1}`,
      },
      open: false,
    },
    {
      data: {
        id: 1,
        scroll_id: `test_2`,
        image: CalcImg,
        paragraph: `${declaration.declTitle2}`,
        text: `${declaration.declText2_1}\n<b> ${declaration.declText2_1a}</b>\n● ${declaration.declText2_1a_1}\n● ${declaration.declText2_1a_2}\n<b> ${declaration.declText2_1b}</b>\n● ${declaration.declText2_1b_1}\n● ${declaration.declText2_1b_2}\n● ${declaration.declText2_1b_3}`,
      },
      open: false,
    },
    {
      data: {
        id: 2,
        scroll_id: `test_3`,
        image: CalcImg,
        paragraph: `${declaration.declTitle3}`,
        text: `${declaration.declText3}`,
      },
      open: false,
    },
    {
      data: {
        id: 3,
        scroll_id: `test_4`,
        image: CalcImg,
        paragraph: `${declaration.declTitle4}`,
        text: `● ${declaration.declText4_1}\n● ${declaration.declText4_2}\n● ${declaration.declText4_3}\n`,
      },
      open: false,
    },
    {
      data: {
        id: 4,
        scroll_id: `test_5`,
        image: CalcImg,
        paragraph: `${declaration.declTitle5}`,
        text: ` ${declaration.declText5}`,
      },
      open: false,
    },
    {
        data: {
          id: 5,
          scroll_id: `test_6`,
          image: CalcImg,
          paragraph: `${declaration.declTitle6}`,
          text: ` ${declaration.declText6}\n●${declaration.declText6_1}\n●${declaration.declText6_2}\n<b>${declaration.declText6_3}</b>\n●${declaration.declText6_4}\n<b>${declaration.declText6_5}<a target="_blank" href="https://triple-c.am/${lang}/news/77">${declaration.declText6_link}</a></b>`,
        },
        open: false,
      },
  ])
  const [data, setData] = useState()
  const form = useRef(null)
  const [USDCurrency, setUSDCCurrency] = useState()
  const [RUBCurrency, setRUBCCurrency] = useState()
  const [buttonDisplay, setButtonDisplay] = useState(false)
  const [responseWrapper, setResponseWrapper] = useState(true)
  const resize = () => {
    console.log('res', responseWrapper)
    const innerWidth = (window.innerWidth);
    (innerWidth >= 1200 && !responseWrapper) ? setResponseWrapper(true) : (responseWrapper && innerWidth < 1200) && setResponseWrapper(false)
  }
  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.addEventListener("resize", resize)
    }
  })
  const toggle = (current) => {
    const data = serviceData.map(d => {
      if (d.data.id === current.data.id && d.open === false) {
        return { ...d, open: true }
      }
      return { ...d, open: false }
    })
    setserviceData(data)
  }
  useEffect(() => {
    triple.get("/api/news")
      .then(res => {
        const sorted = res.data.data.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        console.log('sorted',sorted)
        const filter = sorted.slice(0,4)
        setData(filter)
      })
      .catch(err => console.log(err))

    const rates = {}
    const config = { params: { filters: ["USD", "EUR", "RUB", "GEL", "GBP", "CNY"] } }

    triple.get("/api/rates/last", config)
      .then(res => {
        Object.values(res.data.rates).forEach(rate => rate["ISO"]==="USD" && setUSDCCurrency(parseFloat(rate["Rate"])))
        Object.values(res.data.rates).forEach(rate => rate["ISO"]==="RUB" && setRUBCCurrency(parseFloat(rate["Rate"])))

        this.setState({ rates })
      })
      .catch(err => console.log(err))
  }, [])

  return (
    <Wrapper>
      <SEO
        title={declaration.title}
        description={declaration.description}
        pageContext={pageContext}
        image={SharedLogo}
      />
      <Info>
        <ImagePart>
          <img src={landingImage} alt="" />
        </ImagePart>
        <TextPart>
          <ToggleH2Styled decl>{declaration.pageTitle}</ToggleH2Styled>
          <p>{declaration.description}</p>
        </TextPart>
      </Info>
      <ServiceTitle landing>
        <H2Styled>{declaration.declarationGuide}</H2Styled>
      </ServiceTitle>
      <ContainerRow>
        {serviceData.map((d, id) => (
          <ServiceDropWrap decl={declaration.step} showServiceForm={toggle} data={d} key={id} />
        ))}
      </ContainerRow>
      <ServiceTitle landing>
        <H2Styled>{declaration.individuals}</H2Styled>
      </ServiceTitle>
      <ServicesContainer whyArmenia landing>
        <List className="decl" checked>{declaration.individuals1}</List>
        <List className="decl" checked>{declaration.individuals2}</List>
        <List className="decl" checked>{declaration.individuals3}</List>
        <List className="decl" checked>{declaration.individuals4}</List>
        <List className="decl" checked>{declaration.individuals5}</List>
        <List className="decl" checked>{declaration.individuals6}</List>
      </ServicesContainer>
      <ServiceTitle landing>
        <H2Styled landing>{declaration.whyTriple}</H2Styled>
      </ServiceTitle>
      <ServicesContainer whyArmenia landing>
        <List checked>{declaration.tripleReason1}</List>
        <List checked>{declaration.tripleReason2}</List>
        <List checked>{declaration.tripleReason3}</List>
        <List checked>{declaration.tripleReason4}</List>
        <List checked>{declaration.tripleReason5}</List>
      </ServicesContainer>
      <ServiceTitle landing>
        <H2Styled>{moveBusinessToArmenia.packages}</H2Styled>
      </ServiceTitle>
      <ContainerRow>
        <ServicesContainer landing>
          <h4>{moveBusinessToArmenia.package} 1</h4>
          <Price mb><span>{moveBusinessToArmenia.since}</span><h2>10.000</h2><span>{moveBusinessToArmenia.amd}</span></Price>
          <List checked>{declaration.packageService1}</List>
          <List checked>{declaration.packageService2}</List>
          <List checked>{declaration.packageService3}</List>
          <List checked>{declaration.packageService4}</List>
          <List checked>{declaration.packageService5}</List>
          <List>{declaration.packageService6}</List>
          <List>{declaration.packageService7}</List>
            <SeemoreWrapper onClick={()=>form.current.scrollIntoView()} className="relocation submit_button services_submit">
              {moveBusinessToArmenia.apply}
            </SeemoreWrapper>
        </ServicesContainer>
        <ServicesContainer landing>
          <h4>{moveBusinessToArmenia.package} 2</h4>
          <Price><span>{moveBusinessToArmenia.since}</span><h2>15.000</h2><span>{moveBusinessToArmenia.amd}</span></Price>
          <p className={"currency"} >{declaration.secondPackage}</p>
          <List checked>{declaration.packageService1}</List>
          <List checked>{declaration.packageService2}</List>
          <List checked>{declaration.packageService3}</List>
          <List checked>{declaration.packageService4}</List>
          <List checked>{declaration.packageService5}</List>
          <List checked>{declaration.packageService6}</List>
          <List checked>{declaration.packageService7}</List>
          <SeemoreWrapper onClick={()=>form.current.scrollIntoView()} className="relocation submit_button services_submit">
            {moveBusinessToArmenia.apply}
          </SeemoreWrapper>
        </ServicesContainer>
        <ServicesContainer landing>
          <h4>{moveBusinessToArmenia.package} 3</h4>
          <Price><span>{moveBusinessToArmenia.since}</span><h2>30.000</h2><span>{moveBusinessToArmenia.amd}</span></Price>
          <p className={"currency"}>{declaration.thirdPackage}</p>
          <List checked>{declaration.packageService1}</List>
          <List checked>{declaration.packageService2}</List>
          <List checked>{declaration.packageService3}</List>
          <List checked>{declaration.packageService4}</List>
          <List checked>{declaration.packageService5}</List>
          <List checked>{declaration.packageService6}</List>
          <List checked>{declaration.packageService7}</List>
          <SeemoreWrapper onClick={()=>form.current.scrollIntoView()} className="relocation submit_button services_submit">
            {moveBusinessToArmenia.apply}
          </SeemoreWrapper>
        </ServicesContainer>
      </ContainerRow>
      <SaleWrapper responseWrapper={responseWrapper}>
        <img src={imagge}></img>
        <div>
          <h2>{declaration.special}</h2>
        <SubmitButton onClick={()=>form.current.scrollIntoView()} backgroundColor="white">{moveBusinessToArmenia.apply}</SubmitButton>
        </div>
      </SaleWrapper>
      <ServiceTitle landing>
        <H2Styled>{moveBusinessToArmenia.faq}</H2Styled>
      </ServiceTitle>
      {data && (
        <UsefulNews
          landing
          locale={pageContext.locale}
          apiUrl={apiUrl.apiUrl}
          news={data}
          lang={pageContext.locale}
          pageContext={pageContext}
          buttonDisplay={buttonDisplay}
          setButtonDisplay={setButtonDisplay}
        />
      )}
      <FormColumn ref={form} landing>
        <FormindIviduals declaration={declaration} moveBusinessToArmenia={moveBusinessToArmenia} landing langtext={contact.form_content} lang={pageContext.locale} />
      </FormColumn>
    </Wrapper>
  )
}

export default Index